import styled from "styled-components";

//
const SkeletonContainer = styled.div`
  max-height: 100em;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
`;

export default SkeletonContainer;
