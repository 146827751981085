import styled from 'styled-components';

const Container = styled.div`
width: '100vw',
height: "100vh",
   
`;



export default Container; 